<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import carouselStoreModule from "./store/carouselStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import vSelect from "vue-select";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import apStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";
const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    ItemList,
    ItemForm,
    IconDropdown,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    vSelect,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "category",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const CAROUSEL_AP_STORE_MODULE_NAME = "ap-carousel-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    if (!store.hasModule(CAROUSEL_AP_STORE_MODULE_NAME))
      store.registerModule(CAROUSEL_AP_STORE_MODULE_NAME, carouselStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, apStoreModule);

    const carousel = ref({
      primary_color: '',
      name: '',
      items: []
    });

    const item = ref({
      href: '',
      title: '',
      src: ''
    })


    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const isEditMode = computed(() => props.options.action === "edit");

    const resetCarousel = () => {
      carousel.value = {
        name: "",
        primary_color: "",
        items: [],
      };
    };

    const resetItem = () => {
      item.value = {
        href: '',
        title: '',
        src: ''
      };
    };


    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      showList,
      showForm,
      enableAddMode,
      enableEditMode,
      settings,
      debounceTimer,
      setIndex
    } = UseIndex();


    onMounted(() => {
      resetCarousel();
      const {itemsSelected} = props.options;
      if (itemsSelected) carousel.value = itemsSelected;
      updatePreview();
    });

    const onSubmit = async () => {
      if (isEditMode.value) {
        await updateCarousel();
      } else {
        await createCarousel();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.carousel'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createCarousel = async () => {
      loading.value = true;
      carousel.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      carousel.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "ap-carousel-store/addCarousel",
            rBuilder.clean(carousel.value)
        );

        showSuccessToast(toast, "Carousel", i18n.t("carousel_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_carousel"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateCarousel = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            "ap-carousel-store/updateCarousel",
            {
              id: carousel.value._id,
              carouselData: rBuilder.clean(carousel.value),
            }
        );

        showSuccessToast(toast, "Carousel", i18n.t("carousel_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_carousel"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };


    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          carousel: value || carousel.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        carousel.value.image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.carousel");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const onColorChange = (value) => {
      carousel.value.primary_color = value

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 500);
    };

    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
    };

    const loadItemFromCache = (val) => {
      carousel.value.items[val.index] = val.item
    }


    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      carousel.value.items.push(item.value);
      updatePreview();
      showList();
      resetAll();
    };


    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const updateItem = () => {
      carousel.value.items = carousel.value.items.map((i) => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };


    const onDeleteItem = (index) => {
      carousel.value.items.splice(index, 1)
      updatePreview();
    };


    return {
      isEditMode,
      onColorChange,
      resetAll,
      onSubmit,
      updateTemplate,
      resetData,
      updateItem,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      saveItem,
      carousel,
      onAddItem,
      onEditItem,
      onDeleteItem,
      loading,
      onUploadedImage,
      errorInLoadImage,
      updatePreview,
      settings,
      item
    };
  },
};
</script>

<template>
  <div>
    <b-tabs content-class="mt-2" justified>
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit, invalid }"
      >
        <b-form
            v-if="carousel"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm">

          <b-tab title="Content" active>
            <b-list-group-item style="background: none" class="px-0">
              <b-row>
                <validation-provider
                    class="w-100"
                    #default="validationContext"
                    name="name"
                    rules="required"
                >
                  <b-col cols="12">
                    <b-form-group label="name" label-for="c-name">
                      <template #label>{{ $t("labels.name") }}</template>
                      <b-form-input
                          id="c-name"
                          type="text"
                          v-model="carousel.name"
                          :state="getValidationState(validationContext)"
                      />
                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </b-form-group>
                  </b-col>
                </validation-provider>
              </b-row>

              <b-row>
                <validation-provider
                    class="w-100"
                    #default="validationContext"
                    name="name"
                    rules="required"
                >
                  <b-col cols="12">
                    <b-form-group label="name" label-for="primary-color">
                      <template #label>{{ $t("primary_text_color") }}</template>
                      <b-input
                          type="color"
                          @input="(newValue) => onColorChange(newValue)"
                          id="primary_color"
                          v-model="carousel.primary_color"
                          label="primary_color"
                      />
                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </b-form-group>
                  </b-col>
                </validation-provider>
              </b-row>

            </b-list-group-item>
          </b-tab>

          <b-tab title="Settings">
            <item-list
                :items="carousel.items"
                v-if="settings.status === 'list'"
                @add="onAddItem()"
                :max-count="10"
                @edit="onEditItem($event)"
                @delete="onDeleteItem($event)"
            >
            </item-list>
            <item-form
                class="mt-1"
                @reset="resetAll($event)"
                @save="saveItem()"
                @update="updateItem()"
                @iconSelected="updatePreview()"
                :settings="settings"
                :item="item"
                size="1500x400"
                :size-validation="true"
                :template="editTemplate"
                v-if="settings.status === 'form'"
            >
            </item-form>
          </b-tab>

          <div class="mt-4 d-flex flex-column" style="gap: 5px">
            <b-button
                :disabled="loading || invalid"
                size="md"
                type="submit"
                class="w-100"
                variant="success"
            >
              <b-spinner small label="Loading..." v-if="loading"></b-spinner>
              {{
                isEditMode
                    ? $t("buttons.update_and_apply")
                    : $t("buttons.create_and_apply")
              }}
            </b-button>
            <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-tabs>
  </div>
</template>

<style scoped lang="scss"></style>
