<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import vSelect from "vue-select";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {useToast} from "vue-toastification/composition";
import Multiselect from "vue-multiselect";
import tagStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/TagManagement/store/tagStoreModule";
import lobbyStoreModule
  from "@/views/components/whitelabel-templates/App/config/PageEdit/LobbyManagement/store/lobbyStoreModule";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    Multiselect,
    IconDropdown,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    vSelect,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "tags",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const TAG_AP_STORE_MODULE_NAME = "tag-ap-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    const WHITELABEL_APP_STORE_MODULE_NAME = "app-lobby";

    if (!store.hasModule(WHITELABEL_APP_STORE_MODULE_NAME)) {
      store.registerModule(WHITELABEL_APP_STORE_MODULE_NAME, lobbyStoreModule);
    }
    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(TAG_AP_STORE_MODULE_NAME))
      store.registerModule(TAG_AP_STORE_MODULE_NAME, tagStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, kbStoreModule);

    const tag = ref({
      name: '',
      title: '',
      isFeaturedTag: false,
      image: ''
    });

    const tagOptions = ref([]);

    const rBuild = RequestBuilder();

    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm
    } = UseIndex();


    const isEditMode = computed(() => props.options.action === "edit");

    const resetTag = () => {
      tag.value = {
        name: '',
        title: '',
        isFeaturedTag: false,
        image: ''
      };
    };

    const clientId = computed(
        () => store.state.whitelabelCurrencyNabvar.whitelabel.client
    );


    const getListTags = async () => {
      try {
        const response = await store.dispatch(
            "app-lobby/fetchListTags",
            clientId
        );
        tagOptions.value = response.data;
      } catch (error) {
        console.error("Error fetching tags:", error);
        this.showToast({
          title: i18n.t("error_fetching_tags"),
          variant: "danger",
        });
      }
    }


    onMounted(() => {
      resetTag();
      getListTags()
      const {itemsSelected} = props.options;
      if (itemsSelected) tag.value = itemsSelected;
      updatePreview();
    });

    const onSubmit = async () => {
      if (isEditMode.value) {
        await updateTag();
      } else {
        await createTag();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: "templateData.tags",
            }
        );
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createTag = async () => {
      loading.value = true;
      tag.value.whitelabelId = store.state.whitelabelCurrencyNabvar.whitelabel._id;
      tag.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "ap-tag-store/addTag",
            rBuild.clean(tag.value)
        )

        showSuccessToast(toast, "Tag", i18n.t("tag_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_tag"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateTag = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            "ap-tag-store/updateTag",
            {
              id: tag.value._id,
              tagData: rBuild.clean(tag.value),
            }
        );

        showSuccessToast(toast, "Tag", i18n.t("tag_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_tag"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    }

    const updatePreview = (value) => {
      const template = editTemplate.value;
      const tagM = value || tag.value;
      const index = template.templateData.tags.findIndex(
          (obj) => obj._id === tagM._id
      );
      if (index !== -1) {
        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", {
          ...template,
          templateData: {
            ...template.templateData,
            tags: template.templateData.tags.map((tag, i) =>
                i === index ? tagM : tag
            ),
          },
        });
      }
    };


    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        tag.value.image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit(
          "app-whitelabel-templates/RESET_EDIT_TEMPLATE",
          "templateData.tags"
      );
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const onClearImage = () => {
      tag.value.image = ''
    }

    const addTag = (val) => {
      tag.value.name = val.name
    }

    const removeTag = (val) => {

    }


    return {
      isEditMode,
      addTag,
      removeTag,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      onUploadedImage,
      errorInLoadImage,
      updatePreview,
      tag,
      tagOptions,
      onClearImage
    };
  },
};
</script>

<template>
  <div>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="tag"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >



        <b-row>
          <div class="mx-1 row w-100">
            <b-form-group class="w-100" label="title" label-for="t-title">
              <template #label>Select</template>
              <multiselect
                  class="multiselect w-100"
                  id="tags"
                  :options="tagOptions"
                  :value="tag"
                  :close-on-select="true"
                  :multiple="false"
                  :placeholder="$t('search_filter')"
                  track-by="name"
                  label="name"
                  @select="addTag"
                  @remove="removeTag"
              >
                              <span slot="noResult">
                                {{ $t("no_result") }}
                                .</span
                              >
              </multiselect>
            </b-form-group>
          </div>
        </b-row>

        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="name" label-for="s-name">
                <template #label>{{ $t("labels.name") }}</template>
                <b-form-input
                    readonly
                    id="s-name"
                    type="text"
                    v-model="tag.name"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="title"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="title" label-for="t-title">
                <template #label>{{ $t("title") }}</template>
                <b-form-input
                    id="t-title"
                    type="text"
                    v-model="tag.title"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="featured"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="Is Featured Tag" label-for="t-feature">
                <template #label>featured tag</template>
                <b-form-checkbox
                    class="mt-1"
                    v-model="tag.isFeaturedTag"
                >
                  Featured Tag
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span>{{ $t('labels.image') }}</span> <br/>
            <image-field-form
                :single-button="true"
                :text="$t('labels.image')"
                class="w-100 my-1"
                :value="tag.image"
                path="tags"
                @uploadedImage="onUploadedImage($event, 'image')"
                @error="errorInLoadImage"
                preview="true"
            >
            </image-field-form>

            <div v-if="tag.image" class="w-100">
              <img
                  style="max-width: 100%"
                  :src="tag.image"
                  alt="Uploaded Image"
                  class="mt-1 rounded"
              />
              <b-button class="close-btn" @click="onClearImage('image')" variant="outline-primary" size="sm">
                X
              </b-button>
            </div>
          </b-col>
        </b-row>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<style scoped lang="scss">
@import "~vue-multiselect/dist/vue-multiselect.min.css";
</style>
