<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import vSelect from "vue-select";
import AuthContent
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/HeaderManagement/authContent.vue";
import i18n from "@/libs/i18n";
import headerStoreModule
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/HeaderManagement/store/headerStoreModule";
import kbStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {showErrorToast, showSuccessToast,} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LogoContent
  from "@/views/components/whitelabel-templates/Apostala/config/PageEdit/HeaderManagement/logoContent.vue";
import { required } from "@core/utils/validations/validations";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "FEditHeader",
  components: {
    LogoContent,
    AuthContent,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "header",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const HEADER_AP_STORE_MODULE_NAME = "ap-header-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(HEADER_AP_STORE_MODULE_NAME))
      store.registerModule(HEADER_AP_STORE_MODULE_NAME, headerStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, kbStoreModule);

    const header = ref({
      name: '',
      background_image: '',
      color: '',
      background_color: '',
      auth: {
        hide: false,
        color: '',
        background_color: '',
      },
      logo: {
        height: '',
        hide: false,
        href: '',
        with: ''
      }
    });
    const debounceTimer = ref(null);
    const rBuild = RequestBuilder();

    const {
      editTemplate,
      toast,
      loading,
    } = UseIndex();


    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetHeader();
      const {itemsSelected} = props.options;
      if (itemsSelected) header.value = itemsSelected;
      updatePreview();
    });

    const onColorChange = ({key, value}) => {
      const keys = key.split(".");
      let current = header.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 500);
    };

    const resetHeader = () => {
      header.value = {
        name: '',
        background_image: '',
        color:'',
        background_color: '',
        auth: {
          hide: false,
          color: '',
          background_color: '',
        },
        logo: {
          src: '',
          hide: false,
          href: '',
        }
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          header: value || header.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.header'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeader();
      } else {
        createHeader();
      }
    };

    const updateHeader = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("ap-header-store/updateHeader", {
          id: header.value._id,
          headerData: header.value,
        });

        showSuccessToast(toast, "Header", i18n.t("header_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createHeader = async () => {
      loading.value = true;
      header.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      header.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "ap-header-store/addHeader",
            rBuild.clean(header.value)
        );

        showSuccessToast(toast, "Header", i18n.t("header_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "template.header");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const {refFormObserver, getValidationState, resetForm} = formValidation(
        () => {
        }
    );

    const onUploadedImage = (data, value) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (value === "background_image") {
          header.value.background_image = image || "";
        } else if (value === "logo") {
          header.value.logo.href = image || "";
        }
        updatePreview();
      }
    };

    const onClearImage = (key) => {
      header.value[key] = ''
      updatePreview();
    }


    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    return {
      isEditMode,
      onSubmit,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      header,
      debounceTimer,
      updatePreview,
      onUploadedImage,
      errorInLoadImage,
      onColorChange,
      onClearImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="header"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="name" label-for="h-name">
                <template #label>{{ $t("labels.name") }}</template>
                <b-form-input
                    id="h-name"
                    type="text"
                    v-model="header.name"
                    :state="getValidationState(validationContext)"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="name" label-for="h-name">
                <template #label>{{ $t("labels.background-color") }}</template>
                <b-input
                    type="color"
                    @input="(newValue) => onColorChange({key: 'background_color', value: newValue})"
                    id="background_color"
                    v-model="header.background_color"
                    label="background_color"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>
        
        <b-row>
          <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
          >
            <b-col cols="12">
              <b-form-group label="name" label-for="h-name">
                <template #label>{{ $t("labels.color") }}</template>
                <b-input
                    type="color"
                    @input="(newValue) => onColorChange({key: 'color', value: newValue})"
                    id="color"
                    v-model="header.color"
                    label="color"
                />
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </b-col>
          </validation-provider>
        </b-row>

        <b-row>
          <b-col cols="12">
            <span>{{ $t('labels.backgroundimage') }}</span> <br/>
            <span>1280x300</span>
            <image-field-form
                :single-button="true"
                :text="$t('labels.backgroundimage')"
                class="w-100 mt-1"
                :value="header.background_image"
                size-validation="1280x300"
                path="headers"
                @uploadedImage="onUploadedImage($event, 'background_image')"
                @error="errorInLoadImage"
                preview="true"
            >
            </image-field-form>

            <div v-if="header.background_image" class="w-100">
              <img
                  style="max-width: 100%"
                  :src="header.background_image"
                  alt="Uploaded Image"
                  class="mt-1 rounded"
              />
              <b-button class="close-btn" @click="onClearImage('background_image')" variant="outline-primary" size="sm">
                X
              </b-button>
            </div>
          </b-col>
        </b-row>

        <auth-content :auth="header.auth" @change="onColorChange($event)"></auth-content>

        <logo-content :logo="header.logo"
                      @error="errorInLoadImage($event)"
                      @clear="onClearImage($event)"
                      @change="onUploadedImage($event, 'logo')">
        </logo-content>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
